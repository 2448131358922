import React from 'react';

import { Home } from './pages';

const App = () => (
  <main className="app">
    <Home />
  </main>
);

export default App;
